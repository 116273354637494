import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

// パスを渡せばgatsby-imageで画像を表示してくれるコンポーネントの作成方法
// ref: https://takumon.com/simple-gatsby-image-wrapper

const Image: React.FC<{
  filename: string
  style?: Object
  className?: string
}> = ({ filename, style, className }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 800) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={datas => {
      const image = datas.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })

      if (!image) return null

      // Imgタグでgatsby-imageで最適化された画像を表示する
      const imageSizes = image.node.childImageSharp.sizes
      return <Img fluid={imageSizes} style={style} className={className}/>
    }}
  />
)

export default Image
